<template>
  <div class="post-view" v-if="currentBlog">
    <div class="container quillWrapper">
      <h2 v-if="currentBlog[0]">{{ currentBlog[0].blogTitle }}</h2>
      <div class="creation-info">
          <div class="creation-item">
              <user class="icon"/>
              <h4 v-if="currentBlog[0]">{{currentBlog[0].creator}}</h4>
          </div>
          <div class="creation-item">
              <Time class="icon"/>
              <h4 v-if="currentBlog[0]">{{currentBlog[0].createTime}}</h4>
          </div>
      </div>
      <img :src="currentBlog[0].blogCoverPhotoPath" alt="" v-if="currentBlog[0]" />
      <div v-if="currentBlog[0]" class="post-content ql-editor" v-html="currentBlog[0].blogHTML"></div>
    </div>
    <comments :comments="this.comments" :blogId="this.$route.params.blogid"></comments>
  </div>
</template>

<script>
import user from "../assets/Icons/user-alt-light.svg";
import Time from "../assets/Icons/time-svgrepo-com.svg";
import Comments from "../components/Comments.vue";

export default {
    name:"ViewBlog",
    components:{
      user,
      Time,
      Comments,
    },
    data(){
        return{
            currentBlog:null,
        }
    },
    async mounted(){
        this.currentBlog = await this.$store.getters.getAllBlogs.filter(blog =>{
            return blog.id == this.$route.params.blogid;
        })
        this.$store.state.blog.comments = []
        await this.$store.dispatch('getCommentByBlogId',{blogId:this.$route.params.blogid+""})
        .then((res)=>{
            console.log("get comments",res)
        })
        .catch((err)=>{
            console.log(err)
        })
        console.log("mounted")
    },
    computed:{
      comments:{
        get(){
          return this.$store.getters.getComments;
        }
      },
    }

}
</script>

<style lang="scss">
.post-view {
  min-height: 100%;
  .container {
    max-width: 1000px;
    padding: 60px 25px;
  }
  .ql-editor {
    padding: 0;
  }
  h2 {
    margin-bottom: 16px;
    font-weight: 300;
    font-size: 32px;
  }
  img {
    width: 100%;
    margin-bottom: 32px;
  }
}
h4{
    font-weight: 400;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.creation-info{
  display: flex;
  margin-bottom: 24px;
}
.creation-item{
  display: flex;
  margin-right: 25px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.icon {
  width: 14px;
  height: auto;
  margin-right: 8px;
}
</style>