<template>
  <div class="comment-section">
    <h4 v-if="comments.length != 0">{{ numOfComments }}条评论</h4>
    <hr v-if="comments.length != 0"/>
    <!--一条评论-->
    <!--<BlogCard :post="post" v-for="(post, index) in blogs" :key="index"/>-->
    <div class="comment" v-for="(comment, index) in comments" :key="index">
      <!--第一级评论-->
      <div class="first-class">
        <div class="user">
          <div class="profilephoto" style="background-color: pink;"></div>
          <div class="profileInfo">
            <h4>{{ comment.userName }}</h4>
            <h5>{{ comment.timestamp }}</h5>
          </div>
        </div>
        <div class="comment-content">
          <p>{{ comment.mainContent }}</p>
          <textarea
            class="resTextarea"
            :ref="'main-' + comment.id"
            cols="30"
            rows="10"
            hidden
          ></textarea>
          <button
            class="response-button"
            @click="createCommentResponse(comment.id, comment.userName, 0)"
          >
            回复
          </button>
        </div>
      </div>
      <!--第二级评论-->
      <div
        class="second-class"
        v-for="(res, index) in comment.responses"
        :key="index"
      >
        <div class="user">
          <div
            class="profilephoto-second"
            style="background-color: pink;"
          ></div>
          <div class="profileInfo-second">
            <h4>{{ res.from }}</h4>
            <h5>{{ res.timestamp }}</h5>
            <p>回复</p>
            <h4>{{ res.to }}</h4>
          </div>
        </div>
        <div class="comment-content-second">
          <p>{{ res.mainContent }}</p>
          <textarea
            class="resTextarea"
            :ref="'sub-' + comment.id + '-' + res.id"
            cols="30"
            rows="10"
            hidden
          ></textarea>
          <button
            class="response-button"
            @click="createCommentResponse(comment.id, res.from, res.id)"
          >
            回复
          </button>
        </div>
      </div>
    </div>

    <div class="new-comment">
      <h4>评论</h4>
      <hr />
      <textarea
        name="newComment"
        placeholder="输入评论内容"
        cols="30"
        rows="10"
        v-model="commentMain"
        ref="commentMain"
      ></textarea>
      <button @click="createNewComment">提交评论</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "comments",
  props: ["comments", "blogId"],
  data() {
    return {
      commentMain: "",
    };
  },
  computed: {
    numOfComments() {
      var numOfComments = 0;
      for (let i = 0; i < this.comments.length; i++) {
        numOfComments = numOfComments + 1;
        numOfComments = numOfComments + this.comments[i].responses.length;
      }
      return numOfComments;
    },
  },
  methods: {
    async createNewComment() {
      if (this.commentMain == "") {
        return;
      }
      var now = new Date();
      var timestamp =
        now.toLocaleDateString().replace(/\//g, "-") +
        " " +
        now.toTimeString().substr(0, 8);
      var newComment = {
        id: this.comments.length + 1,
        blogId: parseInt(this.blogId, 10),
        timestamp: timestamp,
        userName: this.$store.state.auth.userName,
        mainContent: this.commentMain,
        responses: [],
      };

      await this.$store
        .dispatch("addComment", newComment)
        .then(() => {
          console.log("add comment successfully");
        })
        .catch((err) => {
          console.log(err);
        });

      this.commentMain = "";
    },
    createCommentResponse(commentId, to, resId) {
      console.log("commentId", commentId, "resId", resId);
      if (resId == 0) {
        this.$refs["main-" + commentId][0].hidden = !this.$refs[
          "main-" + commentId
        ][0].hidden;
        if (
          this.$refs["main-" + commentId][0].hidden &&
          this.$refs["main-" + commentId][0].value != ""
        ) {
          this.createRes(
            commentId,
            to,
            this.$refs["main-" + commentId][0].value
          );

          this.$refs["main-" + commentId][0].value = "";
        }
      } else {
        this.$refs["sub-" + commentId + "-" + resId][0].hidden = !this.$refs[
          "sub-" + commentId + "-" + resId
        ][0].hidden;
        if (
          this.$refs["sub-" + commentId + "-" + resId][0].hidden &&
          this.$refs["sub-" + commentId + "-" + resId][0].value != ""
        ) {
          this.createRes(
            commentId,
            to,
            this.$refs["sub-" + commentId + "-" + resId][0].value
          );

          this.$refs["sub-" + commentId + "-" + resId][0].value = "";
        }
      }
    },
    createRes(commentId, to, mainContent) {
      console.log(this.comments)
      console.log(commentId)
      var now = new Date();
      var timestamp =
        now.toLocaleDateString().replace(/\//g, "-") +
        " " +
        now.toTimeString().substr(0, 8);
      var res = {
        id: this.comments[commentId - 1].responses.length + 1,
        blogId: parseInt(this.blogId, 10),
        commentId: parseInt(commentId, 10),
        from: this.$store.state.auth.userName,
        to: to,
        timestamp: timestamp,
        mainContent: mainContent,
      };
      //插入数据库
      this.$store
        .dispatch("addCommentResponse", res)
        .then((res) => {
          console.log("add commentResponse successfully", res);
        })
        .catch((err) => {
          console.log(err);
        });
      return res;
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.comment-section {
  overflow: hidden;
  width: 1000px;
  margin: 100px auto;
}

hr {
  margin: 5px auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

h4 {
  display: block;
  font-size: 15px;
  font-weight: 800;
  text-align: left;
}

h5,
p {
  font-size: 15px;
  font-style: italic;
}

.comment-section p {
  font-style: normal;
  color: black;
}
.resTextarea {
  resize: none;
  width: 100%;
  height: 60px;
  margin-top: 10px;
  padding: 10px;
  font-size: 15px;
  color: black;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.response-button {
  margin-top: 10px;
  font-weight: 800;
  color: black;
  border: 0;
  background-color: transparent;
}

.user {
  overflow: hidden;
  position: relative;
  height: 60px;
  width: 500px;
  margin-top: 30px;
}

.profilephoto,
.profilephoto-second {
  float: left;
  width: 50px;
  height: 50px;
  margin-top: 5px;
  border-radius: 50%;
  background-position: center center;
  background-size: 50px 50px;
}

.profileInfo {
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
  margin-left: 25px;
}

.profileInfo h5 {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 400;
  font-style: italic;
  color: #999999;
}

.comment-content {
  margin-left: 75px;
  color: #999999;
}

.new-comment {
  margin-top: 50px;
}

.new-comment textarea {
  resize: none;
  width: 970px;
  margin: 20px auto;
  padding: 10px;
  font-size: 15px;
  color: black;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.new-comment button {
  display: block;
  width: 115px;
  height: 43px;
  color: white;
  background-color: #303030;
  border: 0;
}

.second-class {
  margin-top: 30px;
  margin-left: 75px;
}

.profilephoto-second {
  float: left;
  margin-top: 10px;
  width: 40px;
  height: 40px;
}

.profileInfo-second {
  position: absolute;
  left: 40px;
  top: 50%;
  margin-top: -8px;
  display: flex;
  height: 16px;
  line-height: 16px;
}
.profileInfo-second h4 {
  margin-left: 10px;
}
.profileInfo-second h5 {
  margin-left: 10px;
  font-weight: 400;
  color: #999999;
}

.profileInfo-second p {
  margin-left: 10px;
}

.comment-content-second {
  margin-left: 65px;
  color: #999999;
}
</style>
